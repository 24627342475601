<template>
  <div id="main">
    <header>
      <md-toolbar md-elevation="0">
        <div>
          <router-link :to="{ name: 'home' }" class="md-title">
            <img src="@/assets/songfile-logo.png" class="logo" />
          </router-link>

          <router-link
            class="md-xsmall-hide"
            :to="{ name: 'new-release' }"
            v-if="isLoggedIn"
          >
            Get a License
          </router-link>

          <router-link
            class="md-xsmall-hide"
            :to="{ name: 'public-search' }"
            v-if="!isLoggedIn"
          >
            Search
          </router-link>

          <router-link class="md-xsmall-hide" :to="{ name: 'faq' }">
            FAQs
          </router-link>

          <a @click="howItWorks()" class="md-xsmall-hide how-it-works">
            How It Works
          </a>
        </div>

        <div>
          <md-button
            class="md-raised md-primary"
            :to="{ name: 'sign-up' }"
            v-show="!isLoggedIn"
          >
            <md-icon>account_circle</md-icon>

            <span class="md-small-hide">First time here?</span>
          </md-button>

          <div class="login-container" v-if="!isLoggedIn">
            <md-button class="md-raised md-primary" @click="toggleLogin">
              <md-icon>exit_to_app</md-icon>

              <span class="md-small-hide">Login</span>
            </md-button>

            <transition name="fade">
              <Login
                @loginSuccess="toggleLogin"
                :dialog="true"
                v-show="showLogin"
              />
            </transition>
          </div>

          <md-badge
            class="md-primary md-square cart"
            md-position="top"
            :md-content="getAllCartItems.length"
            v-if="isLoggedIn"
          >
            <md-button class="md-raised md-primary" :to="{ name: 'my-cart' }">
              <md-icon>shopping_cart</md-icon>

              <span class="md-small-hide">Cart</span>
            </md-button>
          </md-badge>

          <md-button
            class="md-raised md-primary"
            v-if="isLoggedIn"
            :to="{ name: 'my-profile' }"
          >
            <md-icon>account_circle</md-icon>

            <span class="md-small-hide">My Account</span>
          </md-button>

          <md-button
            class="md-raised md-primary"
            v-if="isLoggedIn"
            @click="logout"
          >
            <md-icon>power_settings_new</md-icon>

            <span class="md-small-hide">Log out</span>
          </md-button>

          <md-button class="menu-btn md-icon-button" @click="showMenu = true">
            <md-icon>menu</md-icon>
          </md-button>
        </div>
      </md-toolbar>
    </header>

    <md-content>
      <router-view @showLogin="toggleLogin()"></router-view>
    </md-content>

    <footer>
      <router-link :to="{ name: 'home' }" class="logo">
        <img src="@/assets/img/songfile-footer-logo.svg" alt="Songfile" />
      </router-link>

      <div class="links">
        <router-link :to="{ name: 'contact-us' }">
          Contact Us
        </router-link>
        <a href="https://www.harryfox.com/#/terms-of-use" target="_blank">
          Terms of Use
        </a>
        <a href="https://www.harryfox.com/#/privacy-policy" target="_blank">
          Privacy Policy - Your Privacy Rights
        </a>
      </div>

      <div class="copyright">
        &copy; {{ new Date().getFullYear() }} The Harry Fox Agency LLC unless
        otherwise specified. Songfile is registered servicemark of The Harry Fox
        Agency LLC.
      </div>

      <div class="sesac-music-group">
        <a href="http://www.sesacmusicgroup.com/" target="_blank">
          <img
            src="@/assets/sesac-music-group-badge.png"
            alt="SESAC Music Group"
          />
        </a>
      </div>
    </footer>

    <md-drawer
      class="md-right nav-menu"
      :md-active.sync="showMenu"
      md-swipeable
    >
      <md-toolbar class="md-transparent" md-elevation="0">
        <router-link class="md-title" :to="{ name: 'home' }">
          <img
            src="@/assets/img/songfile-logo-color.svg"
            alt="Songfile"
            @click="showMenu = false"
          />
        </router-link>
      </md-toolbar>

      <md-list>
        <md-list-item
          :to="{ name: 'new-release' }"
          v-if="isLoggedIn"
          @click="showMenu = false"
        >
          Get a License
        </md-list-item>

        <md-list-item
          :to="{ name: 'public-search' }"
          v-if="!isLoggedIn"
          @click="showMenu = false"
        >
          Search
        </md-list-item>

        <md-list-item :to="{ name: 'faq' }" @click="showMenu = false">
          FAQs
        </md-list-item>

        <md-list-item
          :to="{ name: 'sign-up' }"
          v-if="!isLoggedIn"
          @click="showMenu = false"
        >
          Register
        </md-list-item>

        <md-list-item v-if="!isLoggedIn" @click="toggleLogin">
          Login
        </md-list-item>

        <md-list-item
          :to="{ name: 'my-profile' }"
          v-if="isLoggedIn"
          @click="showMenu = false"
        >
          My Profile
        </md-list-item>

        <md-list-item
          :to="{ name: 'my-projects' }"
          v-if="isLoggedIn"
          @click="showMenu = false"
        >
          My Orders
        </md-list-item>

        <md-list-item
          :to="{ name: 'my-saved-items' }"
          v-if="isLoggedIn"
          @click="showMenu = false"
        >
          My Saved Items
        </md-list-item>
      </md-list>

      <div class="close" @click="showMenu = false">
        <md-icon class="md-size-2x">close</md-icon>
      </div>
    </md-drawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { navLinks } from "@/services/shared.js";
import Login from "@/components/Login.vue";

export default {
  name: "Main",
  components: {
    Login
  },
  props: ["relogin"],
  data: function() {
    return {
      showMenu: false,
      showLogin: false,
      links: navLinks
    };
  },
  computed: {
    ...mapGetters("auth", ["isLoggedIn"]),
    ...mapGetters("release", ["getAllCartItems"])
  },
  methods: {
    toggleLogin: function() {
      if (!this.isLoggedIn) {
        setTimeout(() => document.getElementById("username").focus(), 100);
      }
      this.showLogin = !this.showLogin;
    },
    logout: function() {
      this.showLogin = false;
      this.$store.dispatch("auth/logout");

      if (
        this.$route.matched.filter(item => item.meta.requiresAuth == true)
          .length
      ) {
        this.$router.push({ name: "home" });
      }
    },
    howItWorks: function() {
      if (this.$route.name == "home") {
        document.getElementById("how-it-works").scrollIntoView({
          behavior: "smooth",
          block: "nearest"
        });
      } else {
        this.$router.push({ name: "home" }, function() {
          setTimeout(() => {
            document.getElementById("how-it-works").scrollIntoView({
              behavior: "smooth",
              block: "nearest"
            });
          }, 200);
        });
      }
    }
  },
  created: function() {
    if (this.relogin) {
      this.toggleLogin();
    }
  }
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 200ms ease-in-out;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

#main {
  padding-top: 48px;

  @media all and (min-width: 960px) {
    padding-top: 64px;
  }

  header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 7;
    width: 100vw;
  }

  .nav-menu {
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    background-color: $color-teal;
    position: fixed;

    .md-toolbar {
      justify-content: center;

      .md-title {
        margin: 0;
        padding: 20px 0 0;
      }
    }

    .md-list {
      background-color: transparent;
      margin-top: 40px;

      .md-list-item {
        > * {
          font-size: 24px;
          color: #fff;
          font-family: $font-Gilroy-extrabold;
          text-transform: uppercase;

          .md-list-item-content {
            justify-content: center;
          }
        }
      }
    }

    .close {
      position: absolute;
      background-color: $green;
      bottom: 0;
      text-align: center;
      width: 100%;
      cursor: pointer;

      .md-icon {
        color: #fff;
      }
    }
  }

  .md-toolbar {
    justify-content: space-between;
    color: #000;

    > div {
      display: flex;
      align-items: center;

      a:not(.md-button) {
        color: #000;
        font-family: $font-Gilroy-semibold;
        font-weight: $fw-semibold;
        font-size: 16px;
      }

      .md-title {
        img {
          height: 55px;

          @media (max-width: 767px) {
            height: 35px;
          }
        }
      }

      .login-container {
        position: relative;
      }

      .cart {
        color: #fff;

        .md-button {
          width: 105px;

          .md-ripple {
            justify-content: flex-start;

            .md-button-content {
              justify-content: flex-start;
            }
          }
        }
        &.md-badge-content .md-badge {
          background-color: $color-red;
          color: #fff;
          top: 50%;
          right: 15px;
          transform: translateY(-50%);
          border-radius: 50%;
          width: 22px;
          height: 22px;
        }
      }

      .menu-btn {
        display: inline-block;

        @media all and (min-width: 960px) {
          display: none;
        }
      }
      .how-it-works {
        @media all and (min-width: 960px) and (max-width: 983px) {
          display: none;
        }
      }

      &:first-child {
        justify-content: flex-start;
        font-size: 1.35em;

        a:not(:first-child) {
          margin-left: 40px;
        }
      }
      &:last-child {
        justify-content: flex-end;
      }
      .menu-icon {
        display: none;
      }

      @media all and (max-width: 959px) {
        .menu-icon {
          display: block;
          cursor: pointer;

          .md-icon {
            color: $color-teal;
          }
        }
        .cart > .md-button,
        .login-container > .md-button,
        > .md-button {
          background: none;
          color: #000;
          padding: 0;
          margin: 0;
          min-width: 36px;

          .md-ripple {
            padding: 0;

            .md-icon {
              color: $color-teal;
              margin: 0;
            }
          }

          &:active:before,
          &:hover:before {
            background: none;
          }
        }

        .cart.md-badge-content {
          .md-button {
            width: auto;
          }
          .md-badge {
            top: 5px;
            right: 6px;
            width: 17px;
            height: 17px;
            font-size: 12px;
          }
        }

        .login-container {
          .dialog {
            width: 100vw;
            position: fixed;
            right: 0;

            .md-card {
              width: 100%;
              margin: 0;
            }
          }
        }
      }
    }
  }

  .cart-table {
    tr {
      th.md-table-head {
        .md-table-head-label {
          padding-left: 10px;
          padding-right: 10px;
          white-space: normal;
          line-height: 1;
          overflow: visible;
        }

        &:nth-child(1) {
          width: 15%;
        }
        &:nth-child(2) {
          width: 15%;
        }
        &:nth-child(11),
        &:nth-child(12) {
          width: 5%;

          .md-table-head-container {
            text-align: center;

            .md-table-head-label {
              padding: 0;
            }
          }
        }
      }
    }
    tbody {
      tr {
        td {
          .md-table-cell-container {
            padding-left: 10px;
            padding-right: 10px;
          }
          .delete {
            background-color: #e85757;
            border-color: #e85757;

            .md-icon {
              color: #fff;
            }
          }

          &:nth-child(11),
          &:nth-child(12) {
            .md-table-cell-container {
              padding-left: 0;
              padding-right: 0;
              text-align: center;

              .md-button {
                margin: 0;
              }
            }
          }
        }
      }
    }
    .config {
      .md-table-cell-container {
        text-transform: uppercase;
      }
    }
  }
  .table-footer {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    margin-top: 10px;
    border-top: 1px solid #ccc;

    .total {
      display: flex;
      flex-direction: column;
      margin-right: 10px;

      > div {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        * {
          flex: 0 0 auto;
          margin: 0;
          padding: 5px 0;
          font-size: 1.2em;
        }
        .help {
          width: 35px;
        }
        .name {
          font-weight: bold;
          width: 250px;
        }

        &:last-child {
          .name {
            margin-left: 35px;
          }
          .value {
            font-weight: bold;
          }
        }
      }
    }
  }

  footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 0 50px;
    padding-top: 40px;
    // border-top: 1px solid #ccc;

    .logo {
      img {
        width: 75px;
      }
    }

    .links {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      column-gap: 20px;

      a {
        display: inline-block;
        margin: 20px 0 0;
        flex: 0 0 auto;
      }
    }

    .copyright {
      font-size: 0.9em;
      margin: 20px 8px 0;
      text-align: center;
    }

    .sesac-music-group {
      a {
        display: block;
        margin-top: 20px;

        img {
          filter: invert(1);
          width: 160px;
          display: block;
        }
      }
    }
  }

  .account-container {
    padding: 100px 0 0;
    // width: 70vw;
    // min-width: 900px;
    margin: 0 auto;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;

      > * {
        flex: 0 0 auto;
      }
      h1 {
        color: #000 !important;
        font-size: 1.65em !important;
        margin: 0;
      }
      .md-field {
        width: auto;
        margin: 0;
      }
    }
  }

  .accordion-list {
    .item {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #eee;
      padding-bottom: 20px;
      margin-bottom: 20px;

      .item-header {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: row;

        h4 {
          margin: 0 0 0 10px;
        }

        .number {
          align-items: center;
          background-color: $blue;
          border-radius: 100%;
          color: #fff;
          display: flex;
          flex: 0 0 auto;
          font-size: 2em;
          font-weight: bold;
          height: 40px;
          justify-content: center;
          width: 40px;

          span {
            font-family: $font-Gilroy-extrabold;
            font-size: 24px;
          }

          .md-icon {
            display: none;
          }

          &.done {
            background-color: $green;

            > span {
              display: none;
            }
            .md-icon {
              display: block;
              color: #fff;
            }
          }
        }
      }

      .item-content {
        padding-top: 10px;
        display: none;

        > p {
          font-size: 14px;
        }
        .item-footer {
          margin-top: 20px;
          display: flex;
          align-items: center;
        }

        @media all and (min-width: 990px) {
          padding-left: 60px;

          > p {
            font-size: 16px;
          }
        }
      }

      &.open {
        .item-content {
          display: block;
        }
      }
      &.licensing-terms {
        .item-content {
          .terms {
            padding: 0 20px;
            border: 1px solid #ccc;
            height: 200px;
            overflow: auto;
          }
        }
      }
    }
  }
}
</style>
